export default {
  name: 'scroll',
  methods: {
    smoothVerticalScrolling(id, landing) {
      if (landing === undefined) {
        const element = document.querySelector(id)
        element.scrollIntoView({ behavior: 'smooth' })
      }
      if (landing === 'main') {
        const elementOffset = document.querySelector(id).offsetTop
        const headerHeight = window.innerWidth >= 640 ? 87 : 64
        window.scrollTo({
          top: elementOffset - headerHeight,
          behavior: 'smooth',
        })
      }
    },
  },
}
