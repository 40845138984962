//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import scroll from '@/mixins/scroll'

export default {
  name: 'CommunitySection',
  mixins: [scroll],
  methods: {
    imageContent() {
      const alternativeImg = this.$getBullets(
        'joinOfferScreenJoinScreenPicture'
      )
      if (alternativeImg[0]) {
        return alternativeImg[0].url
      }

      return require('~/assets/img/partner/main/community-section/img1.png')
    },
  },
}
